import {generateUuid} from "./generate-uuid";

export class Stock {
    constructor(
        public partNumber: string,
        public manufacturerName: string|null,
        public digiKeyQuantity: number|string,
        public mouserQuantity: number|string,
        public singlePiecePrice: string|null,
    ) {
    }
}

export const getStock = async (partNumber: string) => {
    const response = await fetch(`/order-samples/stock?partNumber=${partNumber}`);
    const data = await response.json();

    if (!response.ok) throw new Error(data);

    const {
        partNumber: updatedPartNumber,
        manufacturerName,
        digiKeyQuantity,
        mouserQuantity,
        singlePiecePrice
    } = data;

    return new Stock(
        updatedPartNumber,
        manufacturerName,
        digiKeyQuantity,
        mouserQuantity,
        singlePiecePrice
    );
};

const removeStock = async (partNumber: string) => {
    const response = await fetch(`/order-samples/stock?partNumber=${partNumber}`, {
        method: 'DELETE',
        headers: {
            'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')?.attributes.getNamedItem('content')?.value ?? ''
        }
    });
    const data = await response.json();

    if (!response.ok) throw new Error(data);
};

const createRow = (stock: Stock) => {
    const uuid = generateUuid();
    const row = document.createElement('tr');
    row.dataset.partNumber = stock.partNumber;

    let innerHtml = '<td>';
    innerHtml += '<div class="styled-checkbox">';
    innerHtml += '<input form="start-mouser-cart" id="modelCheckbox-' + uuid + '" name="number[]" value="' + stock.partNumber + '" class="styled-checkbox" type="checkbox">';
    innerHtml += '<label for="modelCheckbox-' + uuid + '">&ZeroWidthSpace;</label>';
    innerHtml += '</div>';
    innerHtml += '</td>';
    innerHtml += '<td class="centered-text"><strong>' + stock.partNumber + '</strong></td>';
    innerHtml += '<td class="centered-text digikey-stock">' + stock.digiKeyQuantity + '</td>';
    innerHtml += '<td class="centered-text mouser-stock">' + stock.mouserQuantity + '</td>';
    innerHtml += '<td class="centered-text">' + stock.manufacturerName + '</td>';
    innerHtml += '<td class="centered-text">';
    innerHtml += '<button class="myae-button myae-button--invert myae-button--smaller remove-stock-row">Remove</button>';
    innerHtml += '</td>';

    row.innerHTML = innerHtml;

    return row;
};

const createOrUpdateRow = (stock: Stock) => {
    const table = document.querySelector<HTMLElement>('#sample-table table');
    const tableHead = table!.querySelector<HTMLElement>('thead');
    let tableBody = table!.querySelector<HTMLElement>('tbody');

    if (!tableBody) {
        tableBody = document.createElement('tbody');

        if (tableHead) tableHead.after(tableBody);
        else table!.prepend(tableBody);
    }

    const existingRow = tableBody.querySelector<HTMLTableRowElement>(`tr[data-part-number="${stock.partNumber}"]`);
    const row = createRow(stock);

    if (existingRow) existingRow.replaceWith(row);
    else tableBody.appendChild(row);
};

const removeRow = async (row: HTMLTableRowElement) => {
    await removeStock(row.dataset.partNumber!);

    row.remove();
};

document.addEventListener('DOMContentLoaded', async () => {
    const rows = Array.from<HTMLTableRowElement>(document.querySelectorAll('#sample-table table tr[data-part-number]'));

    await Promise.all(
        rows.map(async row => {
            const digiKeyPlaceholder = row.querySelector('.digikey-placeholder')!;
            const mouserPlaceholder = row.querySelector('.mouser-placeholder')!;
            const manufacturerPlaceholder = row.querySelector('.manufacturer-placeholder')!;

            try {
                const stock = await getStock(row.dataset.partNumber!);

                digiKeyPlaceholder.innerHTML = stock.digiKeyQuantity.toString();
                mouserPlaceholder.innerHTML = stock.mouserQuantity.toString();
                manufacturerPlaceholder.innerHTML = stock.manufacturerName ?? 'Error';
            } catch (error) {
                digiKeyPlaceholder.innerHTML = 'Error';
                mouserPlaceholder.innerHTML = 'Error';
                manufacturerPlaceholder.innerHTML = 'Error';
            }
        })
    );

    document.getElementById('add-sample-form')
        ?.addEventListener('submit', async event => {
            event.preventDefault();

            const invalidPartElement = document.getElementById('invalid-part');
            invalidPartElement!.style.display = 'none';
            invalidPartElement!.innerText = '';

            const formData = new FormData(event.target as HTMLFormElement);
            const partNumber = formData.get('number')!.toString();

            const loadingSpinner = document.querySelector<HTMLElement>('.loading-spinner');
            loadingSpinner!.style.display = 'block';

            try {
                const stock = await getStock(partNumber);

                createOrUpdateRow(stock);
            } catch (error: any) {
                invalidPartElement!.style.display = 'block';
                invalidPartElement!.innerText = error.message;

                formData.set('number', '');
            }

            loadingSpinner!.style.display = 'none';
        });

    document.querySelector('#sample-table table')
        ?.addEventListener('click', async event => {
            const target = event.target as HTMLButtonElement;

            if (target.classList.contains('remove-stock-row')) {
                event.preventDefault();

                await removeRow(target.closest('tr')!);
            }
        });
});
