document.addEventListener('DOMContentLoaded', () => {
    (window as any).dataLayer = (window as any).dataLayer || [];

    document.body.addEventListener('click', event => {
        const element = event.target;

        if (!(element instanceof HTMLElement)) return;

        const taggedElement = element.closest('[data-data-layer-event]');

        if (!(taggedElement && taggedElement instanceof HTMLElement)) return;

        const {
            dataLayerAdded,
            dataLayerEvent,
            dataLayerData,
        } = taggedElement.dataset;

        if (!dataLayerEvent || dataLayerAdded) return;

        event.preventDefault();

        (window as any).dataLayer.push({
            event: dataLayerEvent,
            ...JSON.parse(dataLayerData ?? '{}'),
        });

        taggedElement.dataset.dataLayerAdded = 'true';

        element.click();
    });
});
