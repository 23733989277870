import "./sentry";
import Dropdown from "./dropdown";
import TabbedContent from "./tabbed-content";
import NagBanner from "./nag-banner";
import Modal from "./modal";
import "./find-order";
import "./stock-check";
import "./mouser-sample-stock";
import RohsAutocomplete from "./rohs-search";
import PricebookAutocomplete from "./pricebook-search";
import "./perpage"
import nipAutocomplete from "./nip-search";
import "./npi-confirm"
import "./image-gallery"
import "./customer-password-rules"
import passwordRuleCheck from "./customer-password-rules";
import './waf-hacks'
import './data-layer-events';

const nagBanners: NagBanner[] = [];
const tabbedContent: TabbedContent[] = [];
const dropdowns: Dropdown[] = [];
const modals: Modal[] = [];

Array.prototype.forEach.call(
    document.querySelectorAll('.tabbed-list'),
    function (element) {
        tabbedContent.push(new TabbedContent(element));
    }
)

Array.prototype.forEach.call(
    document.querySelectorAll('.has-children'),
    function (element) {
        dropdowns.push(new Dropdown(element));
    }
);

Array.prototype.forEach.call(
    document.querySelectorAll('.nag-banner'),
    function (element) {
        nagBanners.push(new NagBanner(element));
    }
)

Array.prototype.forEach.call(
    document.querySelectorAll('[data-modal]'),
    function (element) {
        modals.push(new Modal(element));
    }
)

if (document.getElementById('rohs-search')) {
    new RohsAutocomplete();
}

if (document.getElementById('pricebook-search')) {
    new PricebookAutocomplete();
}

if (document.getElementById('npi-search')) {
    new nipAutocomplete();
}

if (document.getElementById('password-validation-container')) {
    new passwordRuleCheck();
}
