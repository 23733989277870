
// Removes 'https://' from URL fields because the Azure WAF incorrectly identifies them as Remote File Inclusion (RFI) attacks and blocks the request
document
    .addEventListener('submit', event => {
        const { target } = event;

        if (!(target instanceof HTMLFormElement)) return;

        target
            .querySelectorAll<HTMLInputElement>('input[name="company_url"],input[name="company_name"]')
            .forEach(element => {
                element.value = element.value
                    .replace('https://', '')
                    .replace('http://', '');
            });
    });
