export default class NagBanner {
    private containerElement: HTMLElement;
    constructor(element) {
        this.containerElement = element;

        setTimeout(
            () => {
                this.containerElement.classList.add('nag-banner--active');
            },
            5000,
        );
    }
}
