document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('find-order')
        ?.addEventListener('submit', event => {
            event.preventDefault();

            const formData = new FormData(event.target as HTMLFormElement);

            window.location.pathname = `/order/${formData.get('number')}`;
        });
});
