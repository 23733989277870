import {getStock} from "./stock-check";

document.addEventListener('DOMContentLoaded', async () => {
    const rows = Array.from<HTMLTableRowElement>(document.querySelectorAll('#mouser-sample-table table tr[data-part-number]'));

    await Promise.all(
        rows.map(async row => {
            const stockPlaceholder = row.querySelector('.stock-placeholder')!;
            const pricePlaceholder = row.querySelector('.price-placeholder')!;

            try {
                const stock = await getStock(row.dataset.partNumber!);

                stockPlaceholder.innerHTML = stock.mouserQuantity.toString();
                pricePlaceholder.innerHTML = stock.singlePiecePrice ?? 'Not available';
            } catch (error) {
                stockPlaceholder.innerHTML = 'Error';
                pricePlaceholder.innerHTML = 'Error';
            }
        })
    );
});
