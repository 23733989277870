document.getElementById('npi-confirm-seen')
    ?.addEventListener('click', async event => {
        const target = event.target as HTMLSelectElement;
        const requestUrl = window.location.origin + '/partnerportal/confirm?id=' + target.dataset.npiId;
        fetch(requestUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const parent= target.closest('.admonition--notice');
                parent?.insertAdjacentHTML( "afterend",
                    '<div id="npi-confirm-success" class="admonition admonition--success admonition--full-width ">\n' +
                    '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>' +
                    '<div class="stack"><p>Thank You</p></div></div>')
                parent?.remove();
            })
            .catch(error => {
                console.error('Error:', error);
            });

    });


if (document.getElementById('npi-confirm-seen')) {
    document.documentElement.addEventListener('mouseleave', function () {
        const modalElement = document.querySelector('[data-modal="npi-confirm-warn"]') as HTMLElement;
        modalElement.classList.remove('closed');
    });
}
