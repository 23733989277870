export default class passwordRuleCheck {

    private responseContainer: HTMLInputElement;
    private passwordInputs: HTMLCollectionOf<Element>;
    private input: HTMLInputElement;
    private passwordErrorFlag: boolean;
    constructor() {
        this.responseContainer = document.getElementById('password-validation-container') as HTMLInputElement;

        if (typeof this.responseContainer === 'undefined')  {
            return;
        }

        this.input = document.getElementsByName('new_password')[0] as HTMLInputElement;
        if (typeof this.input === 'undefined') {
            this.input = document.getElementsByName('password')[0] as HTMLInputElement;
        }

        this.passwordErrorFlag = false;
        this.passwordInputs = document.getElementsByClassName('form-input-password') as HTMLCollectionOf<HTMLElement>;
        if (this.passwordInputs.length == 0) {
            this.passwordInputs = document.getElementsByClassName('log-in-widget__form__item') as HTMLCollectionOf<HTMLElement>;
        }
        Array.from(this.passwordInputs).forEach((item) => {
            let errors = item.getElementsByClassName('form-input-error') as HTMLCollectionOf<HTMLElement>
            if (errors.length) {
                Array.from(errors).forEach((error) => {
                    if (error.innerHTML == "Your password doesn't meet the strength requirements.") {
                        error.hidden = true;
                    }
                    if (error.innerHTML == "The new password must be at least 8 characters.") {
                        error.hidden = true;
                    }
                })
                this.output()
                this.passwordErrorFlag = true;
                this.responseContainer.hidden = !this.passwordErrorFlag
            }
        },this)

        this.input.addEventListener('input', (event) => {
            this.responseContainer.hidden = !this.input.value;
            this.output()
        });
    }

    output () : void {
        let output = '<p>Your password must meet the strength requirements:</p>';
        const upper = document.createElement('li')
        upper.innerText = 'An upper case letter'
        const lower = document.createElement('li')
        lower.innerText = 'A lower case letter'
        const number = document.createElement('li')
        number.innerText = 'A number'
        const symbol = document.createElement('li')
        symbol.innerText = 'A symbol'

        let rules = {
            'upper': {
                'state': !!this.input.value.match('[A-Z]'),
                'el': upper,
            },
            'lower': {
                'state': !!this.input.value.match('[a-z]'),
                'el': lower,
            },
            'number': {
                'state': !!this.input.value.match('[0-9]' ),
                'el': number,
            },
            'symbol': {
                'state': !!this.input.value.match('[^A-Za-z0-9]'),
                'el': symbol,
            },
        };

        const lengthEl = document.createElement('p')
        lengthEl.innerText = '8 characters or more'


        if (this.input.value.length < 8) {
            lengthEl.style.color = '#d53318';
        } else {
            lengthEl.style.color = '#7ab837';
        }

        const ruleGroupEl = document.createElement('p')
        ruleGroupEl.innerText = '3 of the 4 character types'

        let rulesPassCount = 0;
        Object.keys(rules).forEach(key=>{
            if (rules[key].state) {
                rulesPassCount++
                rules[key].el.style.color = '#7ab837';
            } else {
                rules[key].el.style.color = '#d53318';
            }
        })

        if (rulesPassCount < 3) {
            ruleGroupEl.style.color = '#d53318';
        } else {
            ruleGroupEl.style.color = '#7ab837';
        }

        output += lengthEl.outerHTML;
        output += ruleGroupEl.outerHTML;
        output += '<ul>';
        Object.keys(rules).forEach(key=>{
            output += rules[key].el.outerHTML
        })
        output += '</ul>';

        this.responseContainer.innerHTML = output;
    }
}

