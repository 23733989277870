export default class TabbedContent {
    private containerElement: HTMLElement;
    private tablist: HTMLElement;
    private readonly tabs: NodeListOf<HTMLElement>;
    private panels: NodeListOf<HTMLElement>;

    constructor(element) {
        this.containerElement = element;

        const tablist = this.containerElement.querySelector<HTMLElement>('[role="tablist"]');

        if (tablist === null) {
            throw new Error('Tab list element not defined');
        }

        this.tablist = tablist;

        this.tabs = this.tablist.querySelectorAll('[role="tab"]');

        this.panels = this.containerElement.querySelectorAll('[role="tabpanel"]');

        this.containerElement.addEventListener(
            'click',
            (event) => {
                if (
                    event.target === null ||
                    (event.target instanceof HTMLElement && event.target.closest('[role="tab"]') === null)
                ) {
                    return;
                }

                this.containerElement.classList.add('tabbed-list--initialised');

                event.preventDefault();

                Array.prototype.forEach.call(
                    this.tabs,
                    (tab) => {
                        const index = Array.prototype.indexOf.call(this.tabs, tab);

                        if (tab.contains(event.target)) {
                            tab.focus();
                            tab.setAttribute('aria-selected', 'true');

                            this.panels[index].hidden = false;
                        } else {
                            tab.removeAttribute('aria-selected');

                            this.panels[index].hidden = true;
                        }
                    }
                );
            }
        );
    }
}
