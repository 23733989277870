import autocomplete from "autocompleter";

export default class PricebookAutocomplete {

    constructor() {
        interface AutocompleteItem {
            label: string;
            group?: string;
        }

        const input = document.getElementById('pricebook-search') as HTMLInputElement;
        const form = document.getElementById('filter-form') as HTMLFormElement;

        autocomplete<AutocompleteItem>({
            input: input,
            minLength: 3,
            emptyMsg: 'No models found',
            debounceWaitMs: 200,
            showOnFocus: true,
            disableAutoSelect: true,
            fetch: (text: string, update: (items: AutocompleteItem[]) => void) => {
                const requestUrl = window.location.origin + '/pricebook/autocomplete?search=' + text;
                fetch(requestUrl)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        update(data);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });

            },
            onSelect: function (item) {
                input.value = item.label;
                form.submit()
            },
            render: function (item, currentValue) {
                var div = document.createElement('div');
                div.textContent = item.label;
                return div;
            },
        });
    }
}
