import Splide from "@splidejs/splide";

document.addEventListener( 'DOMContentLoaded', function () {
    if (document.getElementsByClassName('image-gallery').length !== 0) {
        var main = new Splide( '#main-carousel', {
            type: 'fade',
            rewind: true,
            pagination: false,
            arrows: false,
        } );

        var thumbnails = new Splide( '#thumbnail-carousel', {
            fixedWidth: 100,
            fixedHeight: 80,
            gap: 0,
            rewind: true,
            pagination: false,
            focus: 'center',
            isNavigation: true,
            cover: true,
        } );

        main.sync( thumbnails );
        main.mount();
        thumbnails.mount();

        const mainImages = document.getElementsByClassName('image-gallery__image');
        for (let i= 0; i < mainImages.length; i++) {
            mainImages[i].addEventListener('click', function () {
                const images = mainImages[i].getElementsByTagName('img');
                const lightbox =  document.querySelector('[data-modal="gallery-modal"]') as HTMLElement;
                if (images[0] && lightbox) {
                    lightbox.classList.remove('closed');
                    lightbox.getElementsByTagName('img')[0].src = images[0].src;
                }
            });
        }
    }
} );
