export default class Modal {
    private containerElement: HTMLElement;
    private modalButton: HTMLElement;
    private modalElement: HTMLElement;
    private modalClose: HTMLElement;
    constructor(element) {
        this.containerElement = element;

        let modalTag = this.containerElement.getAttribute('data-modal');
        this.modalButton = document.querySelector('[data-modalButton="'+modalTag+'"]') as HTMLElement;
        this.modalElement = document.querySelector('[data-modal="'+modalTag+'"]') as HTMLElement;
        this.modalClose = document.querySelector('[data-modalClose="'+modalTag+'"]') as HTMLElement;

        this.modalButton?.addEventListener(
            'click',
            (event) => {
                this.modalElement.classList.remove('closed');
            }
        )
    }
}
const closeModal = (event: Event) => (event.target as HTMLElement).closest('.modal-container')?.classList.add('closed');

document.querySelectorAll('.modal-container .close-modal').forEach(
    element => element.addEventListener('click', closeModal)
);



